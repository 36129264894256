import React, { useEffect, useState } from "react"
import marked from "marked"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

//scss
import "../scss/modules/info-page.scss"
import "../scss/modules/button-component.scss"
import "../components/layout.css"

const IndexPage = data => {

  const handleChange = (site) =>{
    if(site==="site1"){
      document.getElementById("site2").style.display="none";
      document.getElementById("site1").style.display="flex";
      document.getElementById("site1").style.flexDirection="column";

      document.getElementById("site1-btn").style.backgroundColor="#36ab9e8f";
      document.getElementById("site2-btn").style.backgroundColor="transparent";
      document.getElementById("site1-btn").style.color="#23867b";
      document.getElementById("site2-btn").style.color="black";
    }else{
      document.getElementById("site1").style.display="none";
      document.getElementById("site2").style.display="flex";
      document.getElementById("site2").style.flexDirection="column";

      document.getElementById("site2-btn").style.backgroundColor="#36ab9e8f";
      document.getElementById("site1-btn").style.backgroundColor="transparent";
      document.getElementById("site2-btn").style.color="#23867b";
      document.getElementById("site1-btn").style.color="black";
    }
  }

  return(
    <Layout>
      <Seo
        title={data.data.allStrapiInfoPratique.nodes[0].SEO_titre}
        description={data.data.allStrapiInfoPratique.nodes[0].SEO_description}
      />
      <div className={"info-component d-flex justify-content-center"}>
        <div className={"row-info col-lg-10 d-flex"}>
          <div className={"wrapper-content"}>
            <div className={"main-content"}>
              <h1 className={"subtitle text-uppercase"}>
                Centre d'imagerie médicale Créteil-Grand Paris
              </h1>

              <div className={"contact-btn d-flex"}>
                <a
                  className={"button-component"}
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:secretariat@imagerieclaudegalien.com"
                >
                  <button><FontAwesomeIcon icon={faEnvelope}/>  Contactez-nous</button>
                </a>
              </div>

              <div id={"site-dropdown"}>
                <div className={"d-flex justify-content-between"}>
                  <button id={"site1-btn"} onClick={()=>handleChange("site1")}>Radiologie-échographie-mammographie et ostéodensitometrie</button>
                  <button id={"site2-btn"} onClick={()=>handleChange("site2")}>Scanner-IRM</button>
                </div>
              </div>

              <div id={"site1"}>
                <div className={"d-flex flex-row"}>
                  <div className={"col-md-12 info-banner"}>
                    <iframe
                      src={data.data.allStrapiInfoPratique.nodes[0].LienMaps}
                      width="550"
                      height="400"
                      frameBorder="0"
                      aria-hidden="false"
                      title="maps"
                    />
                    <img src={"https://admin.radiologiecreteil.fr"+data.data.allStrapiInfoPratique.nodes[0].photo[0].url}/>
                  </div>
                </div>
                <div className={"info-block d-flex"}>
                  <div className={"col-md-6 before-block "}>
                    <h2 className={"subtitle text-uppercase"}>Horaires</h2>
                    {data.data.allStrapiInfoPratique.nodes[0].gauche.map((x, index) => (
                        <div className={"item"} key={index}>
                          <div dangerouslySetInnerHTML={{ __html: marked(x.champs) }}></div>
                          <span className={"away"}></span>
                        </div>
                      ))}
                  </div>

                  <div className={"col-md-6"}>
                    <h2 className={"subtitle text-uppercase"}>Accès</h2>
                    <div className={"address-block before-block"}>
                      <div className={"info-address"}>
                        {data.data.allStrapiInfoPratique.nodes[0].hopital.map(
                            (x, index) => (
                              <div className={"item pad"} key={index}>
                                <div
                                  dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                                ></div>
                                <span className={"away"}></span>
                              </div>
                            ))
                        }
                      </div>
                      <div className={"content-btn"}>
                        <a
                          className={"button-component"}
                          target="_blank"
                          rel="noreferrer"
                          href={data.data.allStrapiInfoPratique.nodes[0].LienItineraire}
                        >
                          <button>Voir l'Itinéraire</button>
                        </a>
                      </div>
                    </div>
                    {data.data.allStrapiInfoPratique.nodes[0].acces.map((x, index) => (
                        <div className={"item"} key={index}>
                          <div
                            dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                          ></div>
                          <span className={"away"}></span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>

              <div id={"site2"}>
                <div className={"d-flex flex-row"}>
                  <div className={"col-md-12 info-banner"}>
                    <iframe
                      src={data.data.allStrapiInfoPratique.nodes[0].LienMaps2}
                      width="550"
                      height="400"
                      frameBorder="0"
                      aria-hidden="false"
                      title="maps"
                    />
                    <img src={"https://admin.radiologiecreteil.fr"+data.data.allStrapiInfoPratique.nodes[0].photo2[0].url}/>
                  </div>
                </div>
                <div className={"info-block d-flex"}>

                  <div className={"col-md-6 before-block "}>
                    <h2 className={"subtitle text-uppercase"}>Horaires</h2>
                    {data.data.allStrapiInfoPratique.nodes[0].gauche2.map((x, index) => (
                        <div className={"item"} key={index}>
                          <div dangerouslySetInnerHTML={{ __html: marked(x.champs) }}></div>
                          <span className={"away"}></span>
                        </div>
                      ))
                    }
                  </div>

                  <div className={"col-md-6"}>
                    <h2 className={"subtitle text-uppercase"}>Accès</h2>
                    <div className={"address-block before-block"}>
                      <div className={"info-address"}>
                        {data.data.allStrapiInfoPratique.nodes[0].hopital2.map(
                            (x, index) => (
                              <div className={"item pad"} key={index}>
                                <div
                                  dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                                ></div>
                                <span className={"away"}></span>
                              </div>
                            ))
                        }
                      </div>
                      <div className={"content-btn"}>
                        <a
                          className={"button-component"}
                          target="_blank"
                          rel="noreferrer"
                          href={data.data.allStrapiInfoPratique.nodes[0].LienItineraire2}
                        >
                          <button>Voir l'Itinéraire</button>
                        </a>
                      </div>
                    </div>
                    {data.data.allStrapiInfoPratique.nodes[0].acces2.map((x, index) => (
                        <div className={"item"} key={index}>
                          <div
                            dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                          ></div>
                          <span className={"away"}></span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"circles"}>
            <span className={"circlesOne"}></span>
            <span className={"circlesTwo"}></span>
            <span className={"circlesThird"}></span>
            <span className={"circlesFourth"}></span>
            <span className={"circlesFifth"}></span>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
  query QueryInfoPratique {
    allStrapiInfoPratique {
      nodes {
        hopital {
          champs
        }
        gauche {
          champs
        }
        acces {
          champs
        }
        LienItineraire
        LienMaps
        photo{
          url
        }
        SEO_description
        SEO_titre
        hopital2 {
          champs
        }
        gauche2 {
          champs
        }
        acces2 {
          champs
        }
        LienItineraire2
        LienMaps2
        photo2{
          url
        }
      }
    }
    allStrapiUrl {
      nodes {
        ResultatExamenSite1
        PrendreRendeVousSite1
        ResultatExamenSite2
        PrendreRendeVousSite2
      }
    }
  }
`
